/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
  .card:hover {
    background: rgba(var(--card-rgb), 0.1);
    border: 1px solid rgba(var(--card-border-rgb), 0.15);
  }

  .card:hover span {
    transform: translateX(4px);
  }
}

/* Mobile */
@media (max-width: 700px) {
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
}

main {
  margin-top: 24px;
  display: flex;
}

.main-content {
  width: 100%;
  margin-left: 24px;
  padding: 12px 14px 16px 14px;
}
.list-chad {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -8px 0;
}
.chad-item {
  width: 33.333%;
  padding: 8px;
  a {
    display: flex;
    border: 1px solid #000;
    color: #000;
    background: #fcffdf;
    box-shadow: 4px 4px 0px 0px rgba(150, 150, 150, 0.25);
    padding: 9px;
    &:hover {
      opacity: 1;
      background-color: #ad20ef;
      color: #fff;
      transition: background-color 0.2s linear;
      .created-by {
        color: #fff;
      }
      .desc {
        color: #f7dbd7;
      }
    }
  }
  .img {
    min-width: 128px;
    width: 128px;
  }
  .caption {
    padding-left: 16px;
  }
  .created-by {
    color: #1a73e8;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;
    .icon-coin {
      margin: 0 5px;
    }
  }
  .title {
    font-weight: bold;
  }
  .replies {
    color: #ad20ef;
    text-align: right;
  }
  .market-cap {
    margin-top: 4px;
  }
}

@media (min-width: 701px) and (max-width: 1420px) {
  .chad-item {
    width: 50%;
  }
}

.sidebar {
  min-width: 400px;
}
.sidebar-box {
  padding: 12px 14px 16px;
  background-color: #fff;
  margin-bottom: 24px;
}
.chad-profile {
  width: auto;
  padding: 0;
  .img {
    width: 128px;
  }
  a {
    border: 1px solid #c5b4b4;
    background: #faff00;
    box-shadow: 4px 4px 0px 0px rgba(150, 150, 150, 0.25);
  }
  .caption {
    width: 100%;
  }
  .market-cap {
    background-color: #591111;
    color: #fff;
    display: flex;
    padding: 7px 9px;
    margin-top: 7px;
    width: 100%;
    position: relative;
    .number {
      padding-left: 5px;
    }
    .icon {
      position: absolute;
      right: 10px;
      top: 8px;
    }
  }
  .replies {
    text-align: left;
    margin-top: 7px;
  }
}
.sidebar-box-content {
  border: 2px solid #000;
  background: #ffb5b5;
  padding: 16px;
  margin-top: 10px;
}
.sidebar-box-content2 {
  margin-top: 10px;
}
.sidebar {
  .trade-title {
    color: #404040;
    text-align: center;
    font-size: 14px;
    position: relative;
    margin: 8px 0;
    padding: 4px 0;
    &.white {
      span {
        background-color: #fff;
      }
    }
    &::before {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      content: "";
      width: 100%;
      height: 1px;
      background-color: #404040;
      top: 50%;
    }
    span {
      padding: 0 12px;
      position: relative;
      z-index: 9;
      background-color: #ffb5b5;
    }
  }
}
.list-trade {
  li {
    display: flex;
    flex-wrap: wrap;
    padding: 7.5px 8px 8px 8px;
    min-height: 50px;
    align-items: center;
    align-self: stretch;
    background: #ad20ef;
    color: #fff;
    margin-bottom: 8px;
    &.top-trade {
      background: url(../../public/images/bg_top_trade.png) lightgray 50% /
        cover no-repeat;
    }
    .icon {
      border-radius: 100%;
      width: 25px;
    }
    .name,
    .date {
      padding: 0 5px;
    }
    .content {
      display: inline-flex;
      align-items: center;
    }
  }
}

.list-follwing {
  li {
    margin-bottom: 8px;
  }
  a {
    display: inline-flex;
    width: 100%;
    background-image: url(../../public/images/bg_follow.png);
    background-size: 100% 100%;
    color: #fff;
    justify-content: space-between;
    padding: 7px 9px 11px 9px;
    .left {
      width: 60%;
      display: flex;
      align-items: center;
    }
    .name {
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: "MS Sans Serif Bold";
      margin-left: 16px;
    }
    .right {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon {
        width: 25px;
        margin-left: 24px;
      }
    }
  }
}

.page-title {
  color: #000;
  font-family: ThaleahFat;
  font-size: 22px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  .icon {
    width: 20px;
    margin-right: 8px;
  }
}

/* Tablet and Smaller Desktop */
// @media (min-width: 701px) and (max-width: 1360px) {
@media (max-width: 1360px) {
  .main-content {
    margin-left: 0;
  }
}

@media (max-width: 750px) {
  .chad-item {
    width: 100%;
    .img {
      width: 80px;
      min-width: 80px;
    }
    .caption {
      padding-left: 10px;
    }
  }
}

.filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
}
.search-box {
  display: flex;
  background-image: url(../../public/images/bg_search.png);
  background-size: 100% 100%;
  width: 309px;
  padding: 8px 16px;
  align-items: center;
  margin-bottom: 16px;
  * {
    background: none;
    border: none;
  }
  input {
    width: 100%;
    color: #fff;
    font-size: 16px;
    line-height: 1.5;
    &::placeholder {
      color: #fff;
      opacity: 0.7;
    }
    &:focus {
      outline: none;
      border: none;
    }
  }
  button {
    height: 24px;
    min-width: 24px;
    position: relative;
    cursor: pointer;
    margin-right: 16px;
    &:before {
      position: absolute;
      width: 1px;
      height: 24px;
      right: -5px;
      content: "";
      top: 0;
      background-color: #000;
    }
  }
}

.common-checkbox {
  span {
    width: 25px;
    height: 25px;
    display: block;
    border: 1px solid #000;
  }
  input {
    display: none;
    &:checked + span {
      background-image: url(../../public/images/icon_check.png);
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}
.common-select {
  font-size: 16px;
  background-image: url(../../public/images/bg_select.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-width: 164px;
  height: 40px;
  padding: 8px 16px;
  select {
    appearance: none;
    border: none;
    width: 100%;
    background: url(../../public/images/icon_down.png);
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 20px;
    &:focus {
      outline: none;
      border: none;
    }
  }
}

.right-filter {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  .common-checkbox {
    margin: 0 4px;
  }
  .label-checkbox {
    color: #000;
    margin-left: 16px;
  }
  .common-select {
    margin-left: 16px;
  }
}

@media (max-width: 700px) {
  .right-filter {
    flex-wrap: wrap;
    * {
      margin-bottom: 8px;
    }
  }
}

.sidebar-box-content-v2 {
  border: 2px solid #000;
  background: #ffb5b5;
  padding: 0px 16px 16px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
