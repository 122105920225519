.ant-form{
    
    font-size: 16px;

    label, input, button, .ant-form-item-explain-error, .btn-show-more{
        font-family: "MS Sans Serif", "Helvetica Neue", sans-serif;
    }

    button{
        justify-content: center;
        display: flex;
        align-items: center;
        column-gap: 5px;
    }

    .row{
        align-items: flex-start;

        label{
            margin-top: 10px;
        }
        .input-img{
            margin-top: 10px;
        }
    }

    .ant-form-item{
        width: 100%;
        margin-bottom: 0;

        .ant-input{
            font-family: "MS Sans Serif", "Helvetica Neue", sans-serif;
            &:hover, &:focus, &:focus-within{
                background-color: transparent;
                box-shadow: none;
            }

            &-status-error{
                background: url(../../public/images/bg_input.png);
                background-size: 100% 100%;
                border: none;
                height: 45px;
                font-size: 16px;
                padding: 0 15px;
                &:focus {
                    outline: none;
                }
                &::placeholder {
                    color: #000;
                    opacity: 0.7;
                }
            }
        }
    }

    
}