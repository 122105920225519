/* http://meyerweb.com/eric/tools/css/reset/
   v5.0.1 | 20191019
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block;
}
/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}
body {
  line-height: 1;
}
menu,
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

img {
  max-width: 100%;
}
@font-face {
  font-family: ThaleahFat;
  src: url(../../public/fonts/ThaleahFat.ttf);
}
@font-face {
  font-family: "MS Sans Serif Bold";
  src: url("../../public/fonts/MS_Sans_Serif_Bold.ttf");
}
@font-face {
  font-family: "MS Sans Serif";
  src: url("../../public/fonts/MS_Sans_Serif.ttf");
}
a:hover {
  opacity: 0.8;
  transition: all 0.3s;
}

body {
  margin: 0;
  font-family: "MS Sans Serif", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 15px 24px 50px;
  flex-direction: column;
  align-items: center;
  background: url(../../public/images/background.png);
  background-size: cover;
}

.bold {
  font-weight: bold;
  font-family: "MS Sans Serif Bold";
}

header {
  display: flex;
  height: 80px;
  padding: 0px 16px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;

  border: 1px solid #000;
  background: url("../../public/images/bg_nav.png");
  box-shadow: 8px 8px 0px 0px #000;

  width: 100%;

  .logo {
    display: block;
    width: 144px;
    margin-left: 25px;
    img {
      display: inline-block;
      vertical-align: middle;
    }
  }
  .right-buttons {
    display: flex;
  }
  .right-buttons li {
    margin-left: 10px;
    width: 30px;
    height: 30px;
  }

  .right {
    display: flex;
    align-items: center;

    .btn-connect-wrp {
      margin-left: 16px;
    }
    .right-menu {
      margin-left: 15px;
    }
  }
}

.right-menu {
  display: flex;
}
.right-menu .btn-link {
  color: var(--chad-Nero, #fff);
  text-shadow: 2px 2px 0px #000;
  font-family: ThaleahFat, sans-serif;
  font-size: 29px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 82.759% */
  text-transform: uppercase;
  cursor: pointer;
  &.active,
  &:hover {
    color: #fef518;
  }
}
.right-menu li {
  margin-left: 59px;
}
.right-menu li:first-child {
  margin-left: 0;
}

.sidebar-box-title {
  font-family: ThaleahFat;
  font-size: 22px;
  line-height: 1.5;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    position: relative;
    // top: -2px;
  }
}

.box-shadow {
  border: 4px solid #000;
  background: #fff;
  box-shadow: 8px 8px 0px 0px #000;
}

.box-shadow-2 {
  border: 2px solid #000;
  background: #fff;
  box-shadow: 4px 4px 0px 0px #000;
}

.modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  z-index: 99999;
  border: 2px solid #f7dbd7;
  background: #fff;
  max-width: 95% !important;
  width: 600px;
  padding: 11px 13px;
  &.modal-menu {
    z-index: 9999;
    .modal-content {
      margin-top: 35px;
    }
  }
  .close-modal {
    position: absolute;
    right: 13px;
    top: 11px;
  }
  .btn-common {
    margin: 16px auto 0;
    cursor: pointer;
  }
  .cost-info {
    font-size: 16px;
    color: #ff3d5f;
    text-align: center;
    margin-top: 8px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.88);
  z-index: 999;
  &.overlay-menu {
    z-index: 99;
  }
}
.modal-content {
  font-size: 16px;
  padding: 16px;
  border: 2px solid #000;
  background: #ffb5b5;
  line-height: 1.75;
}
.text-purple {
  color: #ad20ef;
}
.text-red {
  color: #ff3d5f;
}
.close-modal {
  border: none;
  outline: none;
  border: none;
  cursor: pointer;
}
.list-how {
  margin-top: 8px;
  li {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.btn-common {
  background-image: url(../../public/images/bg_button_common.png);
  background-size: 100% 100%;
  width: 166px;
  min-height: 40px;
  padding: 8px 16px;
  text-align: center;

  &.grey {
    background-image: url(../../public/images/bg_button_grey.png);
  }
}

.btn-gray {
  background-image: url(../../public/images/profile/bg-btn.png);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 40px;
  width: 166px;
  min-width: 166px;
  font-family: "MS Sans Serif";
}

.modal-form {
  .row {
    display: flex;
    align-items: center;
    margin-top: 8px;
    .name {
      min-width: 100px;
      padding-right: 10px;
    }
  }
  .right {
    width: 100%;
  }
  .common-input {
    width: 100%;
  }
  .btn-show-more {
    color: #ad20ef;
    font-size: 16px;
    background: none;
    border: none;
    margin: 8px auto;
    width: 140px;
    width: 160px;
    text-align: center;
    display: block;
    padding-right: 20px;
    position: relative;
    cursor: pointer;
    &:before {
      content: "";
      background-image: url(../../public/images/icon_arrow_up1.png);
      background-repeat: no-repeat;
      background-position: right center;
      width: 16px;
      height: 17px;
      position: absolute;
      right: 0;
      top: 0;
    }
    &.active:before {
      transform: rotate(180deg);
    }
  }
}
.common-input {
  background: url(../../public/images/bg_input.png);
  background-size: 100% 100%;
  border: none;
  height: 45px;
  font-size: 16px;
  padding: 0 15px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #000;
    opacity: 0.7;
  }
}

@media (max-width: 767px) {
  body {
    padding: 15px;
  }
}
@media (min-width: 768px) {
  .sp {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .pc {
    display: none !important;
  }
}
.menu-modal {
  display: block;
  li {
    width: 100%;
    margin: 0;
    text-align: center;
  }
}

@media (max-width: 1365px) {
  .right-menu {
    li {
      margin-left: 25px;
    }
    .btn-link {
      font-size: 22px;
    }
  }
}
.right-menu.menu-modal {
  li {
    margin: 0;
  }
  .btn-link {
    color: #fef518;
  }
}

@media (max-width: 767px) {
  .modal-form {
    .row {
      display: block;
    }
  }
  header {
    height: 60px;
    padding: 0px 16px;
    .logo {
      width: 144px;
      margin: 0;
    }
    .btn-connect-wrp .wallet-adapter-dropdown .wallet-adapter-button,
    .btn-connect-wrp .wallet-adapter-dropdown .wallet-adapter-button-trigger {
      width: 120px;
      padding: 0 5px;
      font-size: 15px;
      height: 30px;
      background-size: 100% 100%;
    }
  }
}

@media (max-width: 1080px) {
  .md {
    display: none !important;
  }
  .right-menu.pc {
    display: none;
  }
  .right-menu.sp {
    display: block !important;
  }
  .modal.modal-menu {
    background-image: url(../../public/images/bg_menu.png);
    background-size: cover;
    background-position: top center;
    border: none;
    padding: 95px 25px 24px 25px;
  }
  .btn-menu.sp {
    display: block !important;
    background: none;
    border: none;
  }
  .menu-sp {
    text-align: center;
    margin-top: 47px;
    li {
      margin-top: 32px;
      &:first-child {
        margin-top: 0;
      }
    }
    .btn-link {
      // color: #FEF518;
      color: #fff;
      text-shadow: 2px 2px 0px #000;
      font-family: ThaleahFat;
      font-size: 29px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer;
      &:hover {
        color: #fef518;
      }
    }
  }
  .modal-menu {
    .btn-connect-wrp {
      .wallet-adapter-dropdown {
        width: 100%;
        .wallet-adapter-button {
          width: 149px;
          height: 50px;
          padding: 0px 16px;
          margin: 0 auto;
        }
      }
    }
  }

  .menu-social {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 24px;
    left: 0;
    width: 100%;
    li {
      width: 30px;
      height: 30px;
      margin: 0 7.5px;
    }
  }
  .logo-sp {
    margin: 0 auto 47px;
    width: 92px;
    display: block;
  }
}


/* border-pagination */
.b-pagination-outer {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  display: flex
}
.pagination {
  margin: 30px auto 0;
  padding: 0;
  text-align: center
}
.pagination li {
  display: inline;

}
.pagination li a {
  display: block;
  text-decoration: none;
  color: #000;
  padding: 5px 10px;
  border: 1px solid #ddd;
  float: left;

}
.pagination li a {
  -webkit-transition: background-color 0.4s;
  transition: background-color 0.4s
}
.pagination li.selected a {
  background-color: #ad20ef;
  color: #fff;
}
.pagination li a:hover:not(.active) {
  background: #ddd;
}

.input-right-field {
  textarea {
    width: 100%;
  }
}