.detail-main {
  display: flex;
  padding: 0px 14px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  align-self: flex-start;
  margin-left: 0 !important;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.breadcrumbs span {
  color: var(--chad-Black, #000);
  font-family: ThaleahFat;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 33px; /* 150% */
}

.breadcrumbs img {
  display: flex;
  width: 20px;
  height: 20px;
  padding: 2px;
  justify-content: center;
  align-items: center;
}
.detatil-menu {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.detatil-tab-menu {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
.detatil-tab {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.detatil-tab-menu .detatil-tab-text {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid #000;
  background: var(--yellow, #fef518);
}
.detatil-tab-menu .detatil-tab-text span {
  font-family: "MS Sans Serif Bold";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.detatil-info {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 4px;
  align-self: stretch;
  flex-wrap: wrap;
}

.detatil-info-list {
  display: flex;
  align-items: center;
  gap: 6px;
}

.detatil-info img {
  width: 20px;
  height: 20px;
  max-width: 64.61px;
}

.detatil-info span {
  color: var(--chad-Heliotrope, #ad20ef);
  /* body-14 */
  font-family: "MS Sans Serif";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.container {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}
.detail-container {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}
.detail-container-chart {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}
.detail-container-chart .img {
  height: 552px;
  align-self: stretch;
}

.detail-trades {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}

.detail-trades-menu {
  display: flex;
  align-items: center;
  gap: 15px;
  align-self: stretch;
}

.detail-trades-button-list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.detail-trades-button-list button {
  color: var(--chad-Nero, #fff);
  font-family: ThaleahFat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 163px;
  height: 40px;
  padding: 6.495px 12.989px;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
}

.detail-trades-list {
  padding: 8px 8px 8px 16px;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border: 2px solid #000;
}
.detail-trades-list tr th {
  width: 16.67%;
}
.sell span,
.buy span {
  width: 40px;
  align-items: center;
  justify-content: center;
  color: #fff !important;
}
.sell span {
  background-color: #ff3d5f;
}
.buy span {
  background-color: #4ab538;
}
.detail-trades-list tr {
  padding: 10px;
}
.detail-trades-list .title {
  border-bottom: 4px solid #ff3d5f;
}
.detail-trades-list .bold {
  background-color: #ffe1e1;
}
.detail-trades-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.detail-trades-title {
  display: flex;
  align-items: center;
  align-self: stretch;
}

.detail-trades-title span {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 10px;
  color: #000;
  text-align: center;
}
.trades-list-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.trades-list {
  display: flex;
  height: 40px;
  align-items: center;
  align-self: stretch;
}

.siderbar-right {
  margin-left: 20px;
}

.sidebar-box-des {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}
.sidebar-box-des span {
  color: var(--chad-Heliotrope, #ad20ef);
  font-family: "MS Sans Serif";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.sidebar-box-input {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
  position: relative;
  padding: 10px 0;
  align-items: center;
}
.sidebar-box-input input {
  display: flex;
  align-items: flex-start;
  width: 100%;
  position: relative;
  gap: 10px;
  padding: 8px 16px;
  min-height: 56px;
}

.sidebar-box-input span {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 4px;
  align-self: stretch;
  flex-wrap: wrap;
  position: absolute;
  padding: 8px;
  top: 50%;
  transform: translateY(-50%);
}
.sidebar-box-input img {
  width: 28px;
  flex-shrink: 0;
}

.sidebar-buy {
  display: grid;
  justify-content: center;
  align-items: flex-start;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  align-self: stretch;
}

.sidebar-buy span {
  display: flex;
  padding: 3px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border: 1px solid var(--chad-Black, #000);
  background: var(--chad-Robins-Egg-Blue, #ffdbde);
}

.sidebar-button {
  display: flex;
  padding-top: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.sidebar-button button {
  flex-direction: column;
  display: flex;
  width: 164px;
  height: 40px;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  // background-color: #9ef599;
  border-radius: 10px;
}
.sidebar-box-info {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: 2px solid var(--chad-Black, #000);
  background: #ffb5b5;
}
.sidebar-info-sosial {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  align-self: stretch;
  border-bottom: 1px solid #000;
}
.sidebar-info-sosial-logo {
  display: flex;
  max-height: 300px;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  padding-bottom: 20px;
}
.sidebar-info-sosial-logo .logo {
  display: flex;
  width: 80px;
  height: 80px;
  flex-direction: column;
  align-items: flex-start;
}

.sidebar-info-sosial-logo .sosial {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}
.sidebar-info-sosial-logo .sosial-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  row-gap: 4px;
  align-self: stretch;
  flex-wrap: wrap;
}
.sidebar-info-sosial-logo .sosial-info .info {
  display: flex;
  align-items: center;
  gap: 6px;
}

.sidebar-info-sosial-logo .text {
  display: flex;
  align-items: center;
  gap: 6px;
}
.sidebar-info-sosial-logo img {
  width: 20px;
  height: 20px;
}

.sidebar-info-boding {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.sidebar-info-boding .hr {
  width: 100%;
  padding: 3px;
  border: 1px solid #000000;
}
.sidebar-info-boding hr {
  height: 10px;
  background-color: red;
  width: 70%;
}
.table-hoder {
  display: flex;
  padding: 16px 8px 16px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  border: 2px solid var(--chad-Black, #000);
  background: #ffb5b5;
}
.table-hoder .table-list {
  height: 10px;
  flex: 1 0;
  align-self: stretch;
  width: 100%;

  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}
.table-hoder .table-list tr {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.table-hoder .table-list th {
  padding-bottom: 10px;
}
.active-content {
  display: block;
}
.content {
  display: none;
}

.active-tabs {
  background-color: red;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  width: calc(100% + 2px);
  height: 5px;
  background-color: yellow;
}

.row-thread:nth-child(odd) {
  background-color: #ffe1e1;
}

.row-thread:nth-child(odd) {
  background-color: #f6f0f0;
}

.row-my-holding th {
  width: 100% !important;
}

.more-options {
  transition: all 0.5s ease;
  height: 0;
  overflow: hidden;
}

.more-options:target {
  height: 355px;
}

.checkbox {
  height: 23px;
  width: 23px;
  display: inline-block;
  border: 2px solid black;
  background-color: white;
  position: relative;
}

.checkbox-checked::before {
  content: "";
  display: inline-block;
  height: 3px;
  width: 10px;
  position: absolute;
  top: 50%;
  left: 10%;
  background-color: #000;
  transform: rotate(50deg);
}

.checkbox-checked::after {
  content: "";
  display: inline-block;
  height: 3px;
  width: 15px;
  position: absolute;
  top: 40%;
  left: 30%;
  background-color: #000;
  transform: rotate(-60deg);
}

.sidebar-box-input-v2 {
  padding: 6px 0 !important;
}

@media screen and (max-width: 1535px) {
  .sidebar-buy {
    grid-template-columns: repeat(3, 1fr);
  }
  .detail-main {
    flex: auto;
  }
}

@media screen and (max-width: 1279px) {
  .detatil-menu {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .siderbar-right {
    margin-left: 0px;
  }
}

@media screen and (max-width: 1023px) {
  .sidebar-buy {
    grid-template-columns: repeat(5, 1fr);
  }

  .siderbar-right {
    margin: 0 auto;
  }
}

@media screen and (max-width: 640px) {
  .detail-trades-row {
    width: 120%;
    overflow-x: auto;
    font-size: 13px;
  }
  .detail-trades-button-list button {
    font-size: 18px;
    width: 150px;
    height: 35px;
  }

  .detatil-tab-text {
    padding: 4px;
  }
  .detatil-tab-menu {
    align-items: normal;
    gap: 4px;
  }

  .detatil-tab-menu .detatil-tab-text span {
    font-size: 13px;
  }

  .detail-container-chart .img {
    height: 80vh;
  }

  .sidebar-buy {
    grid-template-columns: repeat(3, 1fr);
  }
  .view-table {
    overflow-x: scroll !important;
  }
}

@media screen and (max-width: 515px) {
  .view-table {
    width: 100%;
  }
  .detail-trades-row {
    font-size: 12px;
  }
}

@media screen and (max-width: 400px) {
  .input-options {
    grid-template-columns: 1fr !important;
  }
  .view-table {
    width: 72vw;
  }
}
