.btn-connect-wrp{
    .wallet-adapter-dropdown{
        .wallet-adapter-button, .wallet-adapter-button-trigger{
            background: url(../../public/images/btn_connect_bg.png);
            background-color: transparent;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            width: 138px;
            height: 50px;
            padding: 0 15px;
            color: #000;
            font-family: ThaleahFat, sans-serif;
            font-size: 17px;
            justify-content: center;

            &:hover{
                background-color: transparent;
            }


        }

        .wallet-adapter-button{
            &-start-icon{
                margin-right: 5px;
            }
        }
    }

    .btn-connected{
        background: url(../../public/images/btn_connect_bg.png);
        background-color: transparent;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 138px;
        height: 50px;
        padding: 0 15px;
        color: #000;
        font-family: ThaleahFat, sans-serif;
        font-size: 20px;
        justify-content: center;
        outline: none;
        border: none;
        display: flex;
        align-items: center;

        img{
            height: 24px;
            width: 24px;
            margin-right: 5px;
        }
    }
}

.wallet-adapter-modal-wrapper{
    background: #fff !important;
    border-radius: 0 !important;
    padding: 15px;
    border: 1px solid #FFB5B5;
    flex: unset !important;
    width: 400px;
    max-width: 95% !important;

    .wallet-adapter-modal-title{
        padding: 3px 0;
        color: #000;
        text-align: left;
        line-height: 1;
        display: block;
        font-size: 18px;
        width: 100%;
        font-family: ThaleahFat, sans-serif;
        margin-bottom: 10px;
    }

    .wallet-adapter-modal-button-close{
        top: 12px;
        border-radius: 0;
        padding: 4px;
        right: 15px;
        width: 25px;
        height: 25px;
        background-color: transparent;
        color: transparent;

        background-image: url(../../public/images/btn_close.png);

        svg{
            display: none;
        }
    }

    .wallet-adapter-modal-list{
        background: #FFB5B5;
        border: 2px solid #000;
        padding: 15px 15px 5px;
        display: flex;
        justify-content: space-between;
        height: auto !important;
        flex-wrap: wrap;

        li{
            margin-bottom: 10px;
            max-width: 50%;
            .wallet-adapter-button{
                color: #000;
                font-family: "MS Sans Serif Bold";
                font-size: 15px;

                background-image: url(../../public/images/bg_button_common.png);
                background-size: 100% 100%;
                height: 40px;
                padding: 5px 10px;
                text-align: center;
                width: 162px;
                background-color: transparent;
                justify-content: center;

                span{
                    display: none;
                }
                .wallet-adapter-button-start-icon, img{
                    width: 20px;
                    height: 20px;
                }
            }

            &:last-child{
                .wallet-adapter-button{
                    margin-bottom: 0;
                }
            }
        }

        .wallet-adapter-collapse{
            display: flex;
            justify-content: space-between;
            height: auto !important;
        }
        
    }
    .wallet-adapter-modal-list-more{
        display: none !important;
    }

    
}


.btn-group{
    display: flex;
    justify-content: center;
    align-items: center;
    .btn-common{
        margin: inherit;
        margin-top: 15px;
        margin-right: 10px;
    }
}

.modal-box-title{
    .icon{
        width: 20px;
        top: 0;
    }
}

.modal-profile{
    .address{
        height: 40px;
        background-image: url(../../public/images/modal_profile_address.png);
        background-size: 100% 100%;
        display: flex;
        color: #fff;
        padding: 0 15px;
        align-items: center;
        justify-content: space-between;

        .copy{
            background-image: url(../../public/images/modal_profile_address_copy.png);
            width: 25px;
            height: 25px;
            background-size: contain;
            background-color: transparent;
            border: none;
            cursor: pointer;
        }
    }

    .profile-head{
        background-image: url(../../public/images/modal_profile_head.png);
        min-height: 70px;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        font-size: 24px;
        color: #fff;

        .logo{
            width: 53px;
            height: 53px;
        }
        .name{
            margin: 0 15px;
            
            font-weight: bold;
            
        }
        .followers{
            flex: 1;
            text-align: right;
        }
    }
}

@media (max-width: 767px) {
    .wallet-adapter-modal-wrapper .wallet-adapter-modal-list li {
        width: 100%;
        max-width: 100%;
    }
    .wallet-adapter-modal-wrapper .wallet-adapter-modal-list .wallet-adapter-collapse {
        display: block;
    }
    .wallet-adapter-modal-wrapper .wallet-adapter-modal-list li .wallet-adapter-button {
        margin: 0 auto;
    }
}

